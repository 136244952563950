import { FunctionComponent } from 'react';
import '../../styles/Projects.scss';

import ProjectsGallery from '../modules/ProjectsGallery';
import Footer from '../modules/Footer';

/**
 * Projects page. Contain a projects gallery.
 */
const Projects: FunctionComponent<{}> = () => {
	return (
		<main>
			<header>
				<h1>Jacky Luong</h1>
				<p>
					Hi! 👋 I'm an MEng student at MIT studying Computer Science, and welcome to the abode (of my work).
				</p>
			</header>

			<ProjectsGallery />
			<Footer />
		</main>
	);
};

export default Projects;
