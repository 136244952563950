import { FunctionComponent, useEffect, useState } from 'react';
import '../../styles/GalleryItem.scss';

import { ProjectInfo, SMALL_PAGE_WIDTH } from '../../info';
import useAsset from '../../useAsset';

/**
 * A single item in the projects gallery. Consists of a media thumbnail, a mini title overlay,
 * an expanded overlay (with description), and a button panel for external links and controls.
 * Parent: ProjectsGallery
 *
 * @param index index of the project
 * @param info project info to display
 * @param onload function to let parent know when loaded
 */
const GalleryItem: FunctionComponent<{
	index: number;
	info: ProjectInfo;
	onload: (index: number) => void;
}> = ({ index, info, onload }) => {
	const { media: thumbnail, loading, error } = useAsset(info.media.filename);
	const [active, setActive] = useState(false);

	useEffect(() => {
		if (!loading) onload(index);
	}, [loading, index, onload]);

	const toggleActive = () => setActive(!active);

	if (error) return <></>;

	return (
		<div
			className={`pg-item ${active && 'active'}`}
			style={{ animationDelay: `${200 * index + 250}ms` }}
			onClick={() => window.innerWidth <= SMALL_PAGE_WIDTH && toggleActive()}
		>
			{info.media.type === 'image' ? (
				<img className="pg-thumbnail" src={thumbnail} alt={info.title} />
			) : (
				<video className="pg-thumbnail" src={thumbnail} autoPlay muted loop playsInline />
			)}

			<div className={`pg-overlay ${!active && 'active'}`}>
				<h2 className="pg-overlayHeading">{info.title}</h2>
			</div>

			<div className={`pg-overlay full ${active && 'active'}`}>
				<h2 className="pg-overlayHeading">{info.title}</h2>
				<span className="pg-overlaySubheading">
					<b>{info.subtitle}</b>
				</span>
				{info.body.map((content, index) => (
					<p className="pg-overlayBody" key={`${index}-${content.length}`}>
						{content}
					</p>
				))}
				<p className="pg-overlayBody">Made with {info.techs}.</p>
			</div>

			<ButtonPanel
				buttons={info.buttons}
				control={active ? 'close' : 'info'}
				controlHandler={toggleActive}
			/>
		</div>
	);
};

/**
 * The set of buttons for each gallery item.
 * Parent: GalleryItem
 *
 * @param buttons the external links and images to include
 * @param control which control button to show
 * @param controlHandler the handler for the control button
 */
const ButtonPanel: FunctionComponent<{
	buttons: { image: string; link: string }[];
	control: 'info' | 'close';
	controlHandler: () => void;
}> = ({ buttons, control, controlHandler }) => {
	const { media: controlIcon } = useAsset(`icons/${control}.svg`);
	const [buttonImages, setImages] = useState<{ [image: string]: any }>({});

	// dynamically import button images
	useEffect(() => {
		setImages({});
		for (const { image } of buttons) {
			import(`../../assets/icons/${image}`).then(loadedImage => {
				setImages(images => {
					images[image] = loadedImage.default;
					return { ...images };
				});
			});
		}
	}, [buttons]);

	return (
		<div className="pg-buttons">
			{buttons.map((button, index) => (
				<a href={button.link} target="_blank" rel="noreferrer" key={index}>
					<img src={buttonImages[button.image]} alt={button.image.split('.')[0]} />
				</a>
			))}
			<img src={controlIcon} onClick={controlHandler} alt="control" />
		</div>
	);
};

export default GalleryItem;
