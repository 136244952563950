import { FunctionComponent } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './styles/App.scss';

import NavBar from './components/modules/NavBar';
import Projects from './components/pages/Projects';
import Classes from './components/pages/Classes';
import About from './components/pages/About';

/**
 * Main app component.
 */
const App: FunctionComponent<{}> = () => {
	return (
        <BrowserRouter>
            <NavBar />
			<Routes>
                <Route path="/about" element={<About />} />
                <Route path="/classes" element={<Classes />} />
                <Route path="/" element={<Projects />} />
                <Route path="*" element={<Navigate to="/" />} />
			</Routes>
		</BrowserRouter>
	);
};

export default App;
