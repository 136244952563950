import { useEffect, useState } from 'react';

const useAsset = (
	filename: string
): {
	loading: boolean;
	error: any;
	media: any;
} => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<any>(null);
	const [media, setMedia] = useState<any>(null);

	useEffect(() => {
		const fetchImage = async () => {
			try {
				const response = await import(`./assets/${filename}`); // change relative path to suit your needs
				setMedia(response.default);
			} catch (err) {
				setError(err);
			} finally {
				setLoading(false);
			}
		};

		fetchImage();
	}, [filename]);

	return {
		loading,
		error,
		media
	};
};

export default useAsset;
