import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import '../../styles/ProjectsGallery.scss';

import { projects, SMALL_PAGE_WIDTH } from '../../info';
import GalleryItem from './GalleryItem';

/**
 * The gallery component displaying all projects. Composed of two columns if the page has
 * sufficient width, otherwise one. Animates (fade-in) all items when all media is fully loaded.
 * Parent: Projects
 */
const ProjectsGallery: FunctionComponent<{}> = () => {
	// keeping track of projects with media loaded
	const [projectsLoaded, setLoaded] = useState<boolean[]>(
		Array.from(Array(projects.length), () => false)
	);
	const isLoading = useMemo(() => projectsLoaded.some(loaded => !loaded), [projectsLoaded]);
	const loadedProject = useCallback((index: number) => {
        setLoaded(loaded => {
            loaded[index] = true;
            return [...loaded];
        });
	}, []);

	const numColumns = window.innerWidth <= SMALL_PAGE_WIDTH ? 1 : 2;

	return (
		<div className={`projGallery ${numColumns === 1 && 'narrow'} ${!isLoading && 'loaded'}`}>
			{Array.from(Array(numColumns), (_, column) => (
				<div
					className="pg-column"
					style={{ marginTop: column === 1 ? 30 : 0 }}
					key={column}
				>
					{projects
						.filter((_, pIndex) => pIndex % numColumns === column)
						.map((project, pIndex) => {
							const trueIndex = pIndex * numColumns + column;
							return (
								<GalleryItem
									info={project}
									index={trueIndex}
									key={trueIndex}
									onload={loadedProject}
								/>
							);
						})}
				</div>
			))}
		</div>
	);
};

export default ProjectsGallery;
