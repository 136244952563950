import { FunctionComponent } from 'react';
import '../../styles/Classes.scss';

import { semesters } from '../../info';
import Footer from '../modules/Footer';

/**
 * Classes page. Contains a list of my classes grouped by semesters.
 */
const Classes: FunctionComponent<{}> = () => {
	return (
		<main>
			<header>
				<h1>Coursework</h1>
				<p>The fun rides I've been on at MIT so far.</p>
			</header>
			{semesters.map((semester, index) => (
                <Semester {...semester} key={index} />
			))}

			<Footer />
		</main>
	);
};

/**
 * A section of classes taken, representing a semester.
 * Parent: Classes
 *
 * @param title the title of the section (typically what semester it is)
 * @param classes the list of classes taken during that semester
 */
const Semester: FunctionComponent<{ title: string; classes: string[] }> = ({ title, classes }) => {
	return (
		<div className="semester-container">
			<span className="semester-title">{title}</span>
			<div className="semester-classes">
				{classes.map((classStr, index) => (
					<p className="semester-class" key={index}>
						{classStr}
					</p>
				))}
			</div>
		</div>
	);
};

export default Classes;
