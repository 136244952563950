import { FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/NavBar.scss';

import { ULTRA_SMALL_PAGE_WIDTH } from '../../info';
import logo from '../../assets/icons/logo128.png';

const tabs: { path: string; label: string; newTab?: boolean }[] = [
	{ path: '/', label: 'projects' },
	{ path: '/classes', label: 'classes' },
	{ path: '/about', label: 'about' },
	{ path: '/Jacky_Luong_Resume.pdf', label: 'resume', newTab: true }
];

/**
 * The navigation bar at the top, placed outside of the Router component.
 */
const NavBar: FunctionComponent<{}> = () => {
	const [currentPage, setPage] = useState<number>(0);

	return (
		<nav className="nav-bar">
			{window.innerWidth <= ULTRA_SMALL_PAGE_WIDTH ? null : (
				<div className="logo-container">
					<Link to={'/'} onClick={() => setPage(0)}>
						<img src={logo} alt="logo" />
					</Link>
				</div>
			)}
			<div className="tab-bar">
				{tabs.map((tab, index) => {
					const { path, label, newTab } = tab;
					let extraProps = {};
					if (newTab === true) extraProps = { target: '_blank', rel: 'noreferrer' };

					return (
						<div
							className={`nav-item ${index === currentPage && 'active'}`}
							key={index}
						>
							<Link to={path} {...extraProps} onClick={() => setPage(index)}>
								{label}
							</Link>
						</div>
					);
				})}
			</div>
		</nav>
	);
};

export default NavBar;
