import { FunctionComponent } from 'react';
import '../../styles/Footer.scss';

import useAsset from '../../useAsset';

/**
 * The footer section containing relevant links.
 * Parent: Projects, Classes, About
 */
const Footer: FunctionComponent<{}> = () => {
	const { media: githubIcon } = useAsset('icons/github.svg');
	const { media: linkedinIcon } = useAsset('icons/linkedin.svg');
	const { media: mailIcon } = useAsset('icons/mail.svg');
	const { media: instaIcon } = useAsset('icons/instagram.svg');

	return (
		<footer>
			<div className="links">
				<a href="https://github.com/jacksluong" target="_blank" rel="noreferrer">
					<img src={githubIcon} width="32" alt="github" />
				</a>
				<a href="https://www.linkedin.com/in/jacky-luong/" target="_blank" rel="noreferrer">
					<img src={linkedinIcon} width="32" alt="linkedin" />
				</a>
				<a href="https://www.instagram.com/jkl.ife_/" target="_blank" rel="noreferrer">
					<img src={instaIcon} width="30" alt="instagram" />
				</a>
				<a href="mailto:jkluong@mit.edu" target="_blank" rel="noreferrer">
					<img src={mailIcon} width="32" alt="mail" />
				</a>
			</div>
			<span className="label">made with <span className="love">love</span></span>
		</footer>
	);
};

export default Footer;
