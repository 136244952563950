import { FunctionComponent } from 'react';
import '../../styles/About.scss';

import { descriptorStrings, SMALL_PAGE_WIDTH } from '../../info';
import useAsset from '../../useAsset';
import TypewriterComponent from 'typewriter-effect';
import Footer from '../modules/Footer';

/**
 * About page. Contains information about me, icluding a picture, a description, and a
 * typewriter-animated text component.
 */
const About: FunctionComponent<{}> = () => {
	const { media, loading, error } = useAsset('pfp.jpg');

	return (
		<main>
			<header>
				<h1>About</h1>
				<p>What's a portfolio without its creator?</p>
			</header>
			<section className="bio">
				<div className="pfp-container">
					{!(loading || error) && <img src={media} alt="" />}
				</div>
				{window.innerWidth <= SMALL_PAGE_WIDTH ? <Descriptor prefix="⬆️ " /> : null}
				<div className="desc-container">
					<h2>Hey there!</h2>
					<p>
						Others remember me as someone who eats omelettes with peanut butter and
						jelly, writes their O's clockwise, or skateboards while on crutches, but I
						promise there's more to me that won't raise your eyebrows.
					</p>
					<p>
						My name is Jacky Luong, and I'm an MEng student
						studying Computer Science and Engineering at MIT. Whether
						it's got to do with frontend, backend, mobile app dev, or game dev, I love
						programming, especially for things that can empower others. Aside from that, I enjoy a handful of other things (Marvel
						Cinematic Universe! Soccer!{' '}
						<a
							href="https://www.instagram.com/jkl.ife_/"
							target="_blank"
							rel="noreferrer"
						>
							Photography
						</a>
						!).
					</p>
					<p>
						In other news, I've been trying to pick up guitar and find more people to
						play Catan with, but if you want to chat,{' '}
						<a href="mailto:jkluong@mit.edu" target="_blank" rel="noreferrer">
							drop me a line
						</a>
						!
					</p>
					{window.innerWidth > SMALL_PAGE_WIDTH ? <Descriptor prefix="⬅️ " /> : null}
				</div>
			</section>

			<Footer />
		</main>
	);
};

/**
 * A text component with a looping typewriter animation.
 * Parent: About
 *
 * @param prefix the static string to include on the left of the dynamic text
 */
const Descriptor: FunctionComponent<{ prefix: string }> = ({ prefix }) => {
	return (
		<span>
			{prefix}
			<TypewriterComponent
				options={{
					strings: descriptorStrings,
					delay: 75,
					autoStart: true,
					loop: true
				}}
			/>
		</span>
	);
};

export default About;
